/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { NavLink } from "react-router-dom";
const technikVideos = [
  {
    title: 'Was sind Kunststoffe?',
    size: '3',
    source_tn: '../Images/tn/Technik/201.jpg',
    source_vid: '../Videos/Technik/201.mp4',
    interviewer: 'Prof. Dr. Christian Bonten',
    institute: 'Institut für Kunststofftechnik, Universität Stuttgart',
    duration: '1',
    recommendations: [
      {
        id: 4,
      },
      {
        id: 8,
      },
      {
        id: 6,
      }
    ]
  },
  {
    title: 'Was sind Bio-Kunststoffe?',
    size: '3',
    source_tn: '../Images/tn/Technik/202.jpg',
    source_vid: '../Videos/Technik/202.mp4',
    interviewer: 'Prof. Dr. Christian Bonten',
    institute: 'Institut für Kunststofftechnik, Universität Stuttgart',
    duration: '1',
    recommendations: [
      {
        id: 0,
      },
      {
        id: 5,
      },
      {
        id: 10,
      }
    ]
  },
  {
    title: 'Sind Biokunststoffe biologisch abbaubar?',
    size: '3',
    source_tn: '../Images/tn/Technik/203.jpg',
    source_vid: '../Videos/Technik/203.mp4',
    source_sub: '../Videos/Technik/sub/203.vtt',
    interviewer: 'Prof. Dr. Christian Bonten',
    institute: 'Institut für Kunststofftechnik, Universität Stuttgart',
    duration: '2',
    recommendations: [
      {
        id: 9,
      },
      {
        id: 7,
      },
      {
        id: 2,
      }
    ]
  },
  {
    title: 'Was sind die Kunststoffe der Zukunft?',
    size: '3',
    source_tn: '../Images/tn/Technik/204.jpg',
    source_vid: '../Videos/Technik/204.mp4',
    interviewer: 'Prof. Dr. Christian Bonten',
    institute: 'Institut für Kunststofftechnik, Universität Stuttgart',
    duration: '2',
    recommendations: [
      {
        id: 3,
      },
      {
        id: 0,
      },
      {
        id: 5,
      }
    ]
  },
  {
    title: 'Wie kann das Material bestimmt werden?',
    size: '3',
    source_tn: '../Images/tn/Technik/205.jpg',
    source_vid: '../Videos/Technik/205.mp4',
    interviewer: 'Julian Kattinger',
    institute: ' Institut für Kunststofftechnik, Universität Stuttgart',
    duration: '1',
    recommendations: [
      {
        id: 4,
      },
      {
        id: 8,
      },
      {
        id: 6,
      }
    ]
  },
  {
    title: 'Welche Kunststoffe sind schwer zu identifizieren?',
    size: '3',
    source_tn: '../Images/tn/Technik/206.jpg',
    source_vid: '../Videos/Technik/206.mp4',
    interviewer: 'Julian Kattinger',
    institute: ' Institut für Kunststofftechnik, Universität Stuttgart',
    duration: '1',
    recommendations: [
      {
        id: 0,
      },
      {
        id: 5,
      },
      {
        id: 10,
      }
    ]
  },
  {
    title: 'Was sind Füllstoffe?',
    size: '3',
    source_tn: '../Images/tn/Technik/207.jpg',
    source_vid: '../Videos/Technik/207.mp4',
    source_sub: '../Videos/Technik/sub/207.vtt',
    interviewer: 'Julian Kattinger',
    institute: ' Institut für Kunststofftechnik, Universität Stuttgart',
    duration: '1',
    recommendations: [
      {
        id: 9,
      },
      {
        id: 7,
      },
      {
        id: 2,
      }
    ]
  },
  {
    title: 'Was war die Aufgabe des Instituts für Kunststofftechnik im KuWerKo Projekt?',
    size: '3',
    source_tn: '../Images/tn/Technik/208.jpg',
    source_vid: '../Videos/Technik/208.mp4',
    interviewer: 'Julian Kattinger',
    institute: ' Institut für Kunststofftechnik, Universität Stuttgart',
    duration: '1',
    recommendations: [
      {
        id: 3,
      },
      {
        id: 0,
      },
      {
        id: 5,
      }
    ]
  },
  {
    title: 'Was war ein spannendes Objekt im KuWerKo Projekt?',
    size: '3',
    source_tn: '../Images/tn/Technik/209.jpg',
    source_vid: '../Videos/Technik/209.mp4',
    interviewer: 'Julian Kattinger',
    institute: ' Institut für Kunststofftechnik, Universität Stuttgart',
    duration: '1',
    recommendations: [
      {
        id: 0,
      },
      {
        id: 5,
      },
      {
        id: 10,
      }
    ]
  },
  {
    title: 'Kann recycelter Kunststoff jede Farbe annehmen?',
    size: '3',
    source_tn: '../Images/tn/Technik/210.jpg',
    source_vid: '../Videos/Technik/210.mp4',
    source_sub: '../Videos/Technik/sub/210.vtt',
    interviewer: 'Prof. Dr. Christian Bonten',
    institute: 'Institut für Kunststofftechnik, Universität Stuttgart',
    duration: '1',
    recommendations: [
      {
        id: 9,
      },
      {
        id: 7,
      },
      {
        id: 2,
      }
    ]
  },
  {
    title: 'Was können Ingenieure von alten Kunststoffen lernen?',
    size: '3',
    source_tn: '../Images/tn/Technik/211.jpg',
    source_vid: '../Videos/Technik/211.mp4',
    interviewer: 'Julian Kattinger',
    institute: ' Institut für Kunststofftechnik, Universität Stuttgart',
    duration: '1',
    recommendations: [
      {
        id: 3,
      },
      {
        id: 0,
      },
      {
        id: 5,
      }
    ]
  },
  {
    title: 'Wie sollten wir mit Kunststoffen umgehen?',
    size: '3',
    source_tn: '../Images/tn/Technik/212.jpg',
    source_vid: '../Videos/Technik/212.mp4',
    interviewer: 'Prof. Dr. Christian Bonten',
    institute: 'Institut für Kunststofftechnik, Universität Stuttgart',
    duration: '2',
    recommendations: [
      {
        id: 3,
      },
      {
        id: 0,
      },
      {
        id: 5,
      }
    ]
  },
  {
    title: 'Wie steht es um die Ökobilanz von Kunststoffen?',
    size: '3',
    source_tn: '../Images/tn/Technik/213.jpg',
    source_vid: '../Videos/Technik/213.mp4',
    interviewer: 'Prof. Dr. Christian Bonten',
    institute: 'Institut für Kunststofftechnik, Universität Stuttgart',
    duration: '2',
    recommendations: [
      {
        id: 3,
      },
      {
        id: 0,
      },
      {
        id: 5,
      }
    ]
  },
  {
    title: 'Warum steht das Material nicht auf den Produkten?',
    size: '3',
    source_tn: '../Images/tn/Technik/214.jpg',
    source_vid: '../Videos/Technik/214.mp4',
    interviewer: 'Julian Kattinger',
    institute: ' Institut für Kunststofftechnik, Universität Stuttgart',
    duration: '1',
    recommendations: [
      {
        id: 3,
      },
      {
        id: 0,
      },
      {
        id: 5,
      }
    ]
  },
  {
    title: 'Gibt es einen Unterschied zwischen alten und neuen Objekten?',
    size: '3',
    source_tn: '../Images/tn/Technik/215.jpg',
    source_vid: '../Videos/Technik/215.mp4',
    interviewer: 'Julian Kattinger',
    institute: ' Institut für Kunststofftechnik, Universität Stuttgart',
    duration: '1',
    recommendations: [
      {
        id: 3,
      },
      {
        id: 0,
      },
      {
        id: 5,
      }
    ]
  },
  {
    title: 'Was ist Ihr Lieblingskunststoff?',
    size: '3',
    source_tn: '../Images/tn/Technik/216.jpg',
    source_vid: '../Videos/Technik/216.mp4',
    interviewer: 'Prof. Dr. Christian Bonten',
    institute: 'Institut für Kunststofftechnik, Universität Stuttgart',
    duration: '1',
    recommendations: [
      {
        id: 3,
      },
      {
        id: 0,
      },
      {
        id: 5,
      }
    ]
  },
  // More Videos
]
  
  export default function Technik() {
    return (
      <div className="bg-white">
        <div className="relative bg-sky-500">
      <div className="absolute inset-0">
        <img
          className="h-full w-full object-cover"
          src="../Images/3D-printer.jpg"
          alt=""
        />
        <div className="absolute inset-0 bg-sky-500 mix-blend-multiply" aria-hidden="true" />
      </div>
      <div className="relative mx-auto max-w-7xl py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Technik</h1>
      </div>
    </div>
        

        <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 overflow-y-scroll">
          <ul role="list" className="grid mb-10 grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 pt-28">
            {technikVideos.map((video) => (
              <li key={video.source_tn} className="relative">
                <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                  <img src={video.source_tn} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
                  <NavLink to={"/TechnikVideo/" + technikVideos.findIndex(obj => obj.title === video.title)} type="button" className="absolute inset-0 focus:outline-none">
                    <span className="sr-only">View details for {video.title}</span>
                  </NavLink>
                </div>
                <p className="pointer-events-none mt-2 block text-sm font-medium text-gray-900">{video.title}</p>
                <p className="pointer-events-none block text-sm font-medium text-gray-500">Dauer ca. {video.duration} Minute(n)</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }