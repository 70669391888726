/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { NavLink } from "react-router-dom";
const animations = [
  {
    title: '3D Druck',
    target: '/Druck',
    source:
      '../Images/tn/animationen/tn-3D-Druck.jpg',
  },
  {
    title: 'Mehrkomponentenspritzgießen',
    target: '/Mehrk',
    source:
      '../Images/tn/animationen/tn-Mehrkomponentenspritzgießen.jpg',
  },
  {
    title: 'Spritzguss',
    target: '/Spritz',
    source:
      '../Images/tn/animationen/tn-Spritzguss.jpg',
  },
  {
    title: 'Formpressen',
    target: '/Form',
    source:
      '../Images/tn/animationen/tn-Formpressen.jpg',
  }, 
  {
    title: 'Blasformen',
    target: '/Blas',
    source:
      '../Images/tn/animationen/tn-Blasformen.jpg',
  }
    // More files...
  ]
  
  export default function Animations() {
    return (
      <div className="bg-white">
        <div className="relative bg-rose-600">
      <div className="absolute inset-0">
        <img
          className="h-full w-full object-cover"
          src="../Images/Animations-cover.jpg"
          alt=""
        />
        <div className="absolute inset-0 bg-rose-600 mix-blend-multiply" aria-hidden="true" />
      </div>
      <div className="relative mx-auto max-w-7xl py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Produktionsverfahren</h1>
        <p className="mt-6 max-w-3xl text-xl text-indigo-100">
        Animationen zu häufig in der Kunststofffertigung eingesetzten Produktionsverfahren.
        </p>
      </div>
    </div>
        

        <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 pt-28">
            {animations.map((animation) => (
              <li key={animation.source} className="relative">
                <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                  <img src={animation.source} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
                  <NavLink to={animation.target} type="button" className="absolute inset-0 focus:outline-none">
                    <span className="sr-only">View details for {animation.title}</span>
                  </NavLink>
                </div>
                <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{animation.title}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }