/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { NavLink } from "react-router-dom";

const restaurierungVideos = [
  {
    title: 'Was macht Kunststoffe interessant?',
    size: '3',
    source_tn: '../Images/tn/Restaurierung/101.jpg',
    source_vid: '../Videos/Restaurierung/101.mp4',
    interviewer: 'Prof. Dr. Friederike Waentig',
    institute: 'Institut für Restaurierungs- und Konservierungswissenschaften Technische Hochschule Köln (CICS)',
    duration: '1',
    recommendations: [
      {
        id: 4,
      },
      {
        id: 8,
      },
      {
        id: 6,
      }
    ]
  },
  {
    title: 'Wie wird ein Objekt restauriert?',
    size: '3',
    source_tn: '../Images/tn/Restaurierung/102.jpg',
    source_vid: '../Videos/Restaurierung/102.mp4',
    interviewer: 'Prof. Dr. Friederike Waentig',
    institute: 'Institut für Restaurierungs- und Konservierungswissenschaften Technische Hochschule Köln (CICS)',
    duration: '6',
    recommendations: [
      {
        id: 0,
      },
      {
        id: 5,
      },
      {
        id: 10,
      }
    ]
  },
  {
    title: 'Wie gehen Sie vor, wenn Sie ein Objekt erhalten?',
    size: '3',
    source_tn: '../Images/tn/Restaurierung/103.jpg',
    source_vid: '../Videos/Restaurierung/103.mp4',
    source_sub: '../Videos/Restaurierung/sub/103.vtt',
    interviewer: 'Prof. Dr. Friederike Waentig',
    institute: 'Institut für Restaurierungs- und Konservierungswissenschaften Technische Hochschule Köln (CICS)',
    duration: '1',
    recommendations: [
      {
        id: 9,
      },
      {
        id: 7,
      },
      {
        id: 2,
      }
    ]
  },
  {
    title: 'Wie identifizieren Sie einen Kunststoff?',
    size: '3',
    source_tn: '../Images/tn/Restaurierung/104.jpg',
    source_vid: '../Videos/Restaurierung/104.mp4',
    interviewer: 'Prof. Dr. Friederike Waentig',
    institute: 'Institut für Restaurierungs- und Konservierungswissenschaften Technische Hochschule Köln (CICS)',
    text: 'clloer text',
    duration: '1',
    recommendations: [
      {
        id: 3,
      },
      {
        id: 0,
      },
      {
        id: 5,
      }
    ]
  },
  {
    title: 'Was macht das Restaurieren von Kunststoffen anspruchsvoll?',
    size: '3',
    source_tn: '../Images/tn/Restaurierung/105.jpg',
    source_vid: '../Videos/Restaurierung/105.mp4',
    interviewer: 'Prof. Dr. Friederike Waentig',
    institute: 'Institut für Restaurierungs- und Konservierungswissenschaften Technische Hochschule Köln (CICS)',
    duration: '1',
    recommendations: [
      {
        id: 4,
      },
      {
        id: 8,
      },
      {
        id: 6,
      }
    ]
  },
  {
    title: 'Was sind die besten und schlechtesten Eigenschaften von Kunststoffen?',
    size: '3',
    source_tn: '../Images/tn/Restaurierung/106.jpg',
    source_vid: '../Videos/Restaurierung/106.mp4',
    interviewer: 'Prof. Dr. Friederike Waentig',
    institute: 'Institut für Restaurierungs- und Konservierungswissenschaften Technische Hochschule Köln (CICS)',
    duration: '1',
    recommendations: [
      {
        id: 0,
      },
      {
        id: 5,
      },
      {
        id: 10,
      }
    ]
  },
  {
    title: 'Wie haben Kunststoffe die moderne Welt geprägt?',
    size: '3',
    source_tn: '../Images/tn/Restaurierung/107.jpg',
    source_vid: '../Videos/Restaurierung/107.mp4',
    source_sub: '../Videos/Restaurierung/sub/107.vtt',
    interviewer: 'Prof. Dr. Friederike Waentig',
    institute: 'Institut für Restaurierungs- und Konservierungswissenschaften Technische Hochschule Köln (CICS)',
    duration: '2',
    recommendations: [
      {
        id: 9,
      },
      {
        id: 7,
      },
      {
        id: 2,
      }
    ]
  },
  {
    title: 'Was sind Schwerpunkte in Ihrer Arbeit?',
    size: '3',
    source_tn: '../Images/tn/Restaurierung/108.jpg',
    source_vid: '../Videos/Restaurierung/108.mp4',
    interviewer: 'Prof. Dr. Friederike Waentig',
    institute: 'Institut für Restaurierungs- und Konservierungswissenschaften Technische Hochschule Köln (CICS)',
    text: 'clloer text',
    duration: '1',
    recommendations: [
      {
        id: 3,
      },
      {
        id: 0,
      },
      {
        id: 5,
      }
    ]
  },
  {
    title: 'Werden wir in 100 Jahren noch Kunststoffe verwenden?',
    size: '3',
    source_tn: '../Images/tn/Restaurierung/109.jpg',
    source_vid: '../Videos/Restaurierung/109.mp4',
    interviewer: 'Prof. Dr. Friederike Waentig',
    institute: 'Institut für Restaurierungs- und Konservierungswissenschaften Technische Hochschule Köln (CICS)',
    duration: '1',
    recommendations: [
      {
        id: 0,
      },
      {
        id: 5,
      },
      {
        id: 10,
      }
    ]
  },
  {
    title: 'Wie sollten wir mit Kunststoffen umgehen?',
    size: '3',
    source_tn: '../Images/tn/Restaurierung/110.jpg',
    source_vid: '../Videos/Restaurierung/110.mp4',
    source_sub: '../Videos/Restaurierung/sub/107.vtt',
    interviewer: 'Prof. Dr. Friederike Waentig',
    institute: 'Institut für Restaurierungs- und Konservierungswissenschaften Technische Hochschule Köln (CICS)',
    duration: '2',
    recommendations: [
      {
        id: 9,
      },
      {
        id: 7,
      },
      {
        id: 2,
      }
    ]
  },
  {
    title: 'Was ist Ihre beste Geschichte über Kunststoffe?',
    size: '3',
    source_tn: '../Images/tn/Restaurierung/111.jpg',
    source_vid: '../Videos/Restaurierung/111.mp4',
    interviewer: 'Prof. Dr. Friederike Waentig',
    institute: 'Institut für Restaurierungs- und Konservierungswissenschaften Technische Hochschule Köln (CICS)',
    text: 'clloer text',
    duration: '2',
    recommendations: [
      {
        id: 3,
      },
      {
        id: 0,
      },
      {
        id: 5,
      }
    ]
  },
  // More Videos
]
  
  export default function Restaurierung() {
    return (
      <div className="bg-white">
        <div className="relative bg-amber-500">
      <div className="absolute inset-0">
        <img
          className="h-full w-full object-cover"
          src="../Images/Restaurierung-cover.jpg"
          alt=""
        />
        <div className="absolute inset-0 bg-amber-500 mix-blend-multiply" aria-hidden="true" />
      </div>
      <div className="relative mx-auto max-w-7xl py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Restaurierung</h1>
      </div>
    </div>
        

        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 overflow-y-scroll">
          <ul role="list" className="grid mb-10 grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 pt-28">
            {restaurierungVideos.map((video) => (
              <li key={video.source_tn} className="relative">
                <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                  <img src={video.source_tn} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
                  <NavLink to={"/RestaurierungVideo/" + restaurierungVideos.findIndex(obj => obj.title === video.title)} type="button" className="absolute inset-0 focus:outline-none">
                    <span className="sr-only">View details for {video.title}</span>
                  </NavLink>
                </div>
                <p className="pointer-events-none mt-2 block text-sm font-medium text-gray-900">{video.title}</p>
                <p className="pointer-events-none block text-sm font-medium text-gray-500">Dauer ca. {video.duration} Minute(n)</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }