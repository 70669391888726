import {
  BrowserRouter, 
  Routes, 
  Route,
  useLocation
} from "react-router-dom";
import {AnimatePresence} from 'framer-motion'
import './App.css';
import Formbar from "./Formbar";
import Header from "./Header";
import Bunt from "./Bunt";
import Glossar from './Glossar';
import Interviews from './Interviews';
import Navigation from "./Navigation";
import Start from "./Start";
import RestaurierungVideo from "./RestaurierungVideo";
import TechnikVideo from "./TechnikVideos";
import HomeButton from "./Homebutton";
import Druck from "./Druck";
import Mehrk from "./Mehrk";
import Spritz from "./Spritz";
import Form from "./Form";
import Blas from "./Blas";
import Animations from "./Animations";
import Technik from "./Technik";
import Restaurierung from "./Restaurierung";
import Wie_kann_das_Material_bestimmt_werden from "./technik/Wie_kann_das_Material_bestimmt_werden";



function App() {
  const location = useLocation();
  return (
    <div className="touch-none">

          <HomeButton />
          <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path='/' element={<Start/>} />
                <Route path='/Navigation' element={<Navigation/>} />
                <Route path='/Navigation/Bunt' element={<Bunt/>} />
                  <Route path='/Navigation/Bunt/1903' element={<Glossar/>} />
                <Route path='/Formbar' element={<Formbar/>} />
                <Route path='/Glossar' element={<Glossar/>} />
                <Route path='/Interviews' element={<Interviews/>} />
                <Route path='/Glossar' element={<Glossar />} />
                <Route path="/RestaurierungVideo/:id" element={<RestaurierungVideo />} />
                <Route path="/TechnikVideo/:id" element={<TechnikVideo />} />
                <Route path="/Druck" element={<Druck />} />
                <Route path="/Mehrk" element={<Mehrk />} />
                <Route path="/Spritz" element={<Spritz />} />
                <Route path="/Form" element={<Form />} />
                <Route path="/Blas" element={<Blas />} />
                <Route path="/Produktionsverfahren" element={<Animations />} />
                <Route path="/Restaurierung" element={<Restaurierung />} />
                <Route path="/Technik" element={<Technik />} />
                <Route path="/technik/wie_kann_das_Material_bestimmt_werden" element={<Wie_kann_das_Material_bestimmt_werden />} />
    
            </Routes>
          </AnimatePresence>
          </div>
  );
}
export default App;
