import { NavLink } from 'react-router-dom'
const AnimationRecommendations = [
    {
        title: '3D Druck',
        target: '/Druck',
        source:
          '../Images/tn/animationen/tn-3D-Druck.jpg',
      },
      {
        title: 'Spritzguss',
        target: '/Spritz',
        source:
          '../Images/tn/animationen/tn-Spritzguss.jpg',
      },
      {
        title: 'Formpressen',
        target: '/Form',
        source:
          '../Images/tn/animationen/tn-Formpressen.jpg',
      }, {
        title: 'Mehrkomponentenspritzgießen',
        target: '/Mehrk',
        source:
          '../Images/tn/animationen/tn-Mehrkomponentenspritzgießen.jpg',
      }
  // More files...
]
export default function Blas() {
  return (
    <div className="bg-white">
      <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
        <div className="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
          <svg
            className="absolute top-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
        </div>
      </div>




      <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="mb-8">
          <h2 className="text-lg font-semibold text-rose-500">Animationen</h2>
          <h1 className="mt-1 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
            Produktionsverfahren
          </h1>
        </div>

        <div class="grid grid-cols-4 gap-12">
          <div class="col-span-3 ">
            <video class="rounded-lg object-cover shadow-lg" width="100%" height="auto" controls loop autoplay="autoplay" muted >
              <source src='../Videos/Animations/Blasformen.mp4' type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <h3 className="mt-1 text-4xl font-bold tracking-tight text-gray-900">
            Blasformen
            </h3>
            <p className="mt-5 text-xl text-gray-900">
                Das <b>Blasformen</b> (Hohlkörperblasen) gehört neben
                dem Spritzgießen zu den Verarbeitungsverfahren von
                Thermoplasten, die seit den 1930er Jahren entwickelt
                wurden (Polyethylen, Polypropylen und Weich-PVCs). Das
                Kunststoffgranulat wird aufgeschmolzen und durch eine
                Düse zu einem schlauchförmigen Vorformling ausbildet.
                Dieser wird anschließend vom zweiteiligen Formwerkzeug
                umschlossen. Mit Druckluft wird der Vorformling dann in der
                Form durch einen sogenannten Blasdorn aufgeblasen.
                Der Vorformling formt die Innenkontur des
                Werkzeugs ab und kann nach Abkühlung
                entnommen werden.
            </p>
          </div>

          <div class="">
            <ul className="grid grid-cols-1">
              {AnimationRecommendations.map((AnimationRecommendation) => (
                <li key={AnimationRecommendation.source} className="relative pb-8">
                  <div className="group aspect-video block w-full place-content-center overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                    <img src={AnimationRecommendation.source} alt="" className="block pointer-events-none object-cover group-hover:opacity-75" />
                    <NavLink to={AnimationRecommendation.target} type="button" className="absolute inset-0 focus:outline-none">
                      <span className="sr-only">View details for {AnimationRecommendation.title}</span>
                    </NavLink>
                  </div>
                  <p className="pointer-events-none mt-2 block text-lg font-bold text-gray-900">{AnimationRecommendation.title}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}