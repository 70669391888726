import { NavLink, useParams } from 'react-router-dom';
  const technikVideos = [
    {
      title: 'Was sind Kunststoffe?',
      size: '3',
      source_tn: '../Images/tn/Technik/201.jpg',
      source_vid: '../Videos/Technik/201.mp4',
      source_sub: '../Videos/Technik/sub/201.vtt',
      interviewer: 'Prof. Dr. Christian Bonten',
      institute: 'Institut für Kunststofftechnik, Universität Stuttgart',
      duration: '1',
      recommendations: [
        {
          id: 4,
        },
        {
          id: 8,
        },
        {
          id: 6,
        }
      ]
    },
    {
      title: 'Was sind Bio-Kunststoffe?',
      size: '3',
      source_tn: '../Images/tn/Technik/202.jpg',
      source_vid: '../Videos/Technik/202.mp4',
      source_sub: '../Videos/Technik/sub/202.vtt',
      interviewer: 'Prof. Dr. Christian Bonten',
      institute: 'Institut für Kunststofftechnik, Universität Stuttgart',
      duration: '1',
      recommendations: [
        {
          id: 0,
        },
        {
          id: 5,
        },
        {
          id: 10,
        }
      ]
    },
    {
      title: 'Sind Biokunststoffe biologisch abbaubar?',
      size: '3',
      source_tn: '../Images/tn/Technik/203.jpg',
      source_vid: '../Videos/Technik/203.mp4',
      source_sub: '../Videos/Technik/sub/203.vtt',
      interviewer: 'Prof. Dr. Christian Bonten',
      institute: 'Institut für Kunststofftechnik, Universität Stuttgart',
      duration: '2',
      recommendations: [
        {
          id: 9,
        },
        {
          id: 7,
        },
        {
          id: 2,
        }
      ]
    },
    {
      title: 'Was sind die Kunststoffe der Zukunft?',
      size: '3',
      source_tn: '../Images/tn/Technik/204.jpg',
      source_vid: '../Videos/Technik/204.mp4',
      source_sub: '../Videos/Technik/sub/204.vtt',
      interviewer: 'Prof. Dr. Christian Bonten',
      institute: 'Institut für Kunststofftechnik, Universität Stuttgart',
      duration: '2',
      recommendations: [
        {
          id: 3,
        },
        {
          id: 0,
        },
        {
          id: 5,
        }
      ]
    },
    {
      title: 'Wie kann das Material bestimmt werden?',
      size: '3',
      source_tn: '../Images/tn/Technik/205.jpg',
      source_vid: '../Videos/Technik/205.mp4',
      source_sub: '../Videos/Technik/sub/205.vtt',
      interviewer: 'Julian Kattinger',
      institute: ' Institut für Kunststofftechnik, Universität Stuttgart',
      duration: '1',
      recommendations: [
        {
          id: 4,
        },
        {
          id: 8,
        },
        {
          id: 6,
        }
      ]
    },
    {
      title: 'Welche Kunststoffe sind schwer zu identifizieren?',
      size: '3',
      source_tn: '../Images/tn/Technik/206.jpg',
      source_vid: '../Videos/Technik/206.mp4',
      source_sub: '../Videos/Technik/sub/206.vtt',
      interviewer: 'Julian Kattinger',
      institute: ' Institut für Kunststofftechnik, Universität Stuttgart',
      duration: '1',
      recommendations: [
        {
          id: 0,
        },
        {
          id: 5,
        },
        {
          id: 10,
        }
      ]
    },
    {
      title: 'Was war die Aufgabe des Instituts für Kunststofftechnik im KuWerKo Projekt?',
      size: '3',
      source_tn: '../Images/tn/Technik/208.jpg',
      source_vid: '../Videos/Technik/208.mp4',
      source_sub: '../Videos/Technik/sub/208.vtt',
      interviewer: 'Julian Kattinger',
      institute: ' Institut für Kunststofftechnik, Universität Stuttgart',
      duration: '1',
      recommendations: [
        {
          id: 3,
        },
        {
          id: 0,
        },
        {
          id: 5,
        }
      ]
    },
    {
      title: 'Was war ein spannendes Objekt im KuWerKo Projekt?',
      size: '3',
      source_tn: '../Images/tn/Technik/209.jpg',
      source_vid: '../Videos/Technik/209.mp4',
      source_sub: '../Videos/Technik/sub/209.vtt',
      interviewer: 'Julian Kattinger',
      institute: ' Institut für Kunststofftechnik, Universität Stuttgart',
      duration: '1',
      recommendations: [
        {
          id: 0,
        },
        {
          id: 5,
        },
        {
          id: 10,
        }
      ]
    },
    {
      title: 'Kann recycelter Kunststoff jede Farbe annehmen?',
      size: '3',
      source_tn: '../Images/tn/Technik/210.jpg',
      source_vid: '../Videos/Technik/210.mp4',
      source_sub: '../Videos/Technik/sub/210.vtt',
      interviewer: 'Prof. Dr. Christian Bonten',
      institute: 'Institut für Kunststofftechnik, Universität Stuttgart',
      duration: '1',
      recommendations: [
        {
          id: 9,
        },
        {
          id: 7,
        },
        {
          id: 2,
        }
      ]
    },
    {
      title: 'Was können Ingenieure von alten Kunststoffen lernen?',
      size: '3',
      source_tn: '../Images/tn/Technik/211.jpg',
      source_vid: '../Videos/Technik/211.mp4',
      source_sub: '../Videos/Technik/sub/211.vtt',
      interviewer: 'Julian Kattinger',
      institute: ' Institut für Kunststofftechnik, Universität Stuttgart',
      duration: '1',
      recommendations: [
        {
          id: 3,
        },
        {
          id: 0,
        },
        {
          id: 5,
        }
      ]
    },
    {
      title: 'Wie sollten wir mit Kunststoffen umgehen?',
      size: '3',
      source_tn: '../Images/tn/Technik/212.jpg',
      source_vid: '../Videos/Technik/212.mp4',
      source_sub: '../Videos/Technik/sub/212.vtt',
      interviewer: 'Prof. Dr. Christian Bonten',
      institute: 'Institut für Kunststofftechnik, Universität Stuttgart',
      duration: '2',
      recommendations: [
        {
          id: 3,
        },
        {
          id: 0,
        },
        {
          id: 5,
        }
      ]
    },
    {
      title: 'Wie steht es um die Ökobilanz von Kunststoffen?',
      size: '3',
      source_tn: '../Images/tn/Technik/213.jpg',
      source_vid: '../Videos/Technik/213.mp4',
      source_sub: '../Videos/Technik/sub/213.vtt',
      interviewer: 'Prof. Dr. Christian Bonten',
      institute: 'Institut für Kunststofftechnik, Universität Stuttgart',
      duration: '2',
      recommendations: [
        {
          id: 3,
        },
        {
          id: 0,
        },
        {
          id: 5,
        }
      ]
    },
    {
      title: 'Warum steht das Material nicht auf den Produkten?',
      size: '3',
      source_tn: '../Images/tn/Technik/214.jpg',
      source_vid: '../Videos/Technik/214.mp4',
      source_sub: '../Videos/Technik/sub/214.vtt',
      interviewer: 'Julian Kattinger',
      institute: ' Institut für Kunststofftechnik, Universität Stuttgart',
      duration: '1',
      recommendations: [
        {
          id: 3,
        },
        {
          id: 0,
        },
        {
          id: 5,
        }
      ]
    },
    {
      title: 'Gibt es einen Unterschied zwischen alten und neuen Objekten?',
      size: '3',
      source_tn: '../Images/tn/Technik/215.jpg',
      source_vid: '../Videos/Technik/215.mp4',
      source_sub: '../Videos/Technik/sub/215.vtt',
      interviewer: 'Julian Kattinger',
      institute: ' Institut für Kunststofftechnik, Universität Stuttgart',
      duration: '1',
      recommendations: [
        {
          id: 3,
        },
        {
          id: 0,
        },
        {
          id: 5,
        }
      ]
    },
    {
      title: 'Was ist Ihr Lieblingskunststoff?',
      size: '3',
      source_tn: '../Images/tn/Technik/216.jpg',
      source_vid: '../Videos/Technik/216.mp4',
      source_sub: '../Videos/Technik/sub/216.vtt',
      interviewer: 'Prof. Dr. Christian Bonten',
      institute: 'Institut für Kunststofftechnik, Universität Stuttgart',
      duration: '1',
      recommendations: [
        {
          id: 3,
        },
        {
          id: 0,
        },
        {
          id: 5,
        }
      ]
    },
    // More Videos
  ]





export default function TechnikVideo() {
  var { id } = useParams();
  function playPause(e) {
    e.preventDefault();
    var myVideo = document.getElementById("video1");
    if (myVideo.paused) myVideo.play();
    else myVideo.pause();
  }
    return (
      <div className="bg-white">
        <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
          <div className="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
            <svg
              className="absolute top-12 left-full translate-x-32 transform"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
          </div>
        </div>


      
      
        <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="mb-8">
            <h2 className="text-lg font-semibold text-sky-500">Interview</h2>
            <NavLink to='/Technik'>
            <h1 className="mt-1 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
              Kunststofftechnik
            </h1>
            </NavLink>
          </div>

        <div className="grid grid-cols-4 gap-12">
            <div className="col-span-3 ">
                <video className="rounded-lg object-cover shadow-lg" width="100%" height="auto" controls src={technikVideos[id].source_vid} type="video/mp4">
                <track
                        label="Deutsch"
                        kind="subtitles"
                        srclang="de"
                        src= {technikVideos[id].source_sub}
                        default />
                    Your browser does not support the video tag.  
                </video>
                <h3 className="mt-1 text-4xl font-bold tracking-tight text-gray-900">
                  {technikVideos[id].title}
                </h3>
                <h5 className="pt-2 text-lg font-semibold text-sky-500">
                {technikVideos[id].interviewer}
                </h5>
                <h6 className="font-semibold text-gray-500">
                {technikVideos[id].institute}
                </h6>
            </div>

            <div className=''>
            <ul className="grid grid-cols-1">
              {technikVideos[id].recommendations.map((reco) => (
                <li key={technikVideos[reco.id].source_tn} className="relative pb-8">
                  <div className="group aspect-video block w-full place-content-center overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-sky-400 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                    <img src={technikVideos[reco.id].source_tn} alt="" className="block pointer-events-none object-cover group-hover:opacity-75" />
                    <NavLink to={"/TechnikVideo/" + reco.id} type="button" className="absolute inset-0 focus:outline-none">
                      <span className="sr-only">View details for {technikVideos[reco.id].title}</span>
                    </NavLink>
                  </div>
                  <p className="pointer-events-none mt-2 block text-lg font-bold text-gray-900">{technikVideos[reco.id].title}</p>
                  <p className="pointer-events-none block text-sm font-medium text-gray-500">Dauer ca. {technikVideos[reco.id].duration} Minuten</p>
                </li>
              ))}
            </ul>

            </div>

        </div>

       

        </div>
      </div>
    )
  }