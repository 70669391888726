import { NavLink } from "react-router-dom";
export default function Start() {
    return (
      <div className="relative z-50 bg-white pb-96 overflow-hidden">
        <NavLink to ='/Navigation' className="h-screen" >
          <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="sm:max-w-lg pt-48">
              <span className="font leading-loose	px-5 text-8xl font-bold tracking-tight uppercase bg-black text-white">
                Klasse 
              </span>
              <br/>
              <span className="font leading-loose	mx-96 px-5 text-8xl font-bold tracking-tight bg-black text-white">
                & 
              </span>
              <br/>
              <span className="font leading-loose	mx-32 px-5 text-8xl font-bold uppercase tracking-tight bg-black text-white">
                Masse 
              </span>
              <p className="mt-8 text-3xl font-bold text-black">
                Kunststoffdesign im Alltag
              </p>
            </div>
            <div>
              <div className="mt-10">
                {/* Decorative image grid */}
                <div
                  aria-hidden="true"
                  className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
                >
                  <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 ">
                    <div className="flex items-center space-x-6 lg:space-x-8">
                      <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="h-80 w-64 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
                          <img
                            src="../Images/Start/expo_1.jpg"
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                        <div className="h-80 w-64 overflow-hidden rounded-lg">
                          <img
                            src="../Images/Start/expo_2.jpg"
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                      </div>
                      <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="h-80 w-64 overflow-hidden rounded-lg">
                          <img
                            src="../Images/Start/expo_3.jpg"
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                        <div className="h-80 w-64 overflow-hidden rounded-lg">
                          <img
                            src="../Images/Start/expo_4.jpg"
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                        <div className="h-80 w-64 overflow-hidden rounded-lg">
                          <img
                            src="../Images/Start/expo_5.jpg"
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                      </div>
                      <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="h-80 w-64 overflow-hidden rounded-lg">
                          <img
                            src="../Images/Start/expo_6.jpg"
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                        <div className="h-80 w-64 overflow-hidden rounded-lg">
                          <img
                            src="../Images/Start/expo_7.jpg"
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </NavLink>
      </div>
    )
  }