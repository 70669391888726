import { PlayIcon as PlayIconOutline } from '@heroicons/react/24/outline'
const glossarImages = [
    {
      title: 'Warum steht das Material nicht auf den Produkten?',
      size: '3',
      source:
        'https://images.unsplash.com/photo-1493217465235-252dd9c0d632?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
    },
    {
      title: 'Was können Ingenieure von alten Kunststoffen lernen?',
      size: '4',
      source:
        'https://images.unsplash.com/photo-1585997769088-95219fac87f4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
    },
    {
      title: 'Was war die Aufgabe des Instituts für Kunststofftechnik im KuWerKo Projekt?',
      size: '2',
      source:
        'https://images.unsplash.com/photo-1609850808399-82fb0bb6fb32?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
    }
    // More files...
  ]
export default function Wie_kann_das_Material_bestimmt_werden() {
    return (
      <div className="bg-white">
        <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
          <div className="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
            <svg
              className="absolute top-12 left-full translate-x-32 transform"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
          </div>
        </div>


      
      
        <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="mb-8">
            <h2 className="text-lg font-semibold text-sky-600">Interview</h2>
            <h1 className="mt-1 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
              Kunststofftechnik
            </h1>
          </div>

        <div class="grid grid-cols-4 gap-12">
            <div class="col-span-3 ">
                <video class="rounded-lg object-cover shadow-lg" width="100%" height="auto" controls>
                    <source src='../Videos/Technik/T_Wie_kann_man_die_Art_des_Kunststoffes_bestimmen.mp4' type="video/mp4"/>
                    Your browser does not support the video tag.  
                </video>
                <h3 className="mt-1 text-4xl font-bold tracking-tight text-gray-900">
                  Wie kann das Material bestimmt werden?
                </h3>
                <h5 className="pt-2 text-lg font-semibold text-sky-600">
                Julian Kattinger
                </h5>
                <h6 className="font-semibold text-gray-500">
                  Institut für Kunststofftechnik, Universität Stuttgart
                </h6>
            </div>

            <div class="">
            <ul className="grid grid-cols-1">
          {glossarImages.map((glossarImage) => (
            <li key={glossarImage.source} className="relative pb-8">
              <div className="group aspect-video block w-full place-content-center overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <img src={glossarImage.source} alt="" className="block pointer-events-none object-cover group-hover:opacity-75" />
                <button type="button" className="absolute inset-0 focus:outline-none">
                  <span className="sr-only">View details for {glossarImage.title}</span>
                </button>
              </div>
              <p className="pointer-events-none mt-2 block text-lg font-bold text-gray-900">{glossarImage.title}</p>
              <p className="pointer-events-none block text-sm font-medium text-gray-500">Dauer ca. {glossarImage.size} Minuten</p>
            </li>
          ))}
        </ul>

            </div>
        </div>

       

        </div>
      </div>
    )
  }