import { NavLink } from "react-router-dom";

export default function Zeitstrahl() {
    return(
        <div className="grid gap-8 row-gap-0 lg:grid-cols-6 py-10">

            <div className="relative text-center">
                <NavLink to='/Navigation' className="flex items-center justify-center w-20 h-20 mx-auto mb-4 rounded-full relative transition-all bg-yellow-500 hover:w-24 hover:top-[-8px] hover:h-24">
                    <h6 className="mb-2 text-2xl font-extrabold">1901</h6>
                </NavLink>
                <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>  
            </div>

            <div className="relative text-center">
                <NavLink to='1902' className="flex items-center justify-center w-20 h-20 mx-auto mb-4 rounded-full transition-all bg-blue-500 hover:w-24 hover:h-24">
                    <h6 className="mb-2 text-2xl font-extrabold">1902</h6>
                </NavLink>
                <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>  
            </div> 

            <div className="relative text-center">
                <NavLink to='1903' className="flex items-center justify-center w-20 h-20 mx-auto mb-4 rounded-full transition-all bg-green-500 hover:w-24 hover:h-24">
                    <h6 className="mb-2 text-2xl font-extrabold">1903</h6>
                </NavLink>
                <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>  
            </div> 

            <div className="relative text-center">
                <NavLink to='1904' className="flex items-center justify-center w-20 h-20 mx-auto mb-4 rounded-full transition-all bg-purple-500 hover:w-24 hover:h-24">
                    <h6 className="mb-2 text-2xl font-extrabold">1904</h6>
                </NavLink>
                <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>  
            </div> 

            <div className="relative text-center">
                <NavLink to='1905' className="flex items-center justify-center w-20 h-20 mx-auto mb-4 rounded-full transition-all bg-pink-500 hover:w-24 hover:h-24">
                    <h6 className="mb-2 text-2xl font-extrabold">1905</h6>
                </NavLink>
                <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>  
            </div> 

            <div className="relative text-center">
                <NavLink to='1906' className="flex items-center justify-center w-20 h-20 mx-auto mb-4 rounded-full transition-all bg-orange-500 hover:w-24 hover:h-24">
                    <h6 className="mb-2 text-2xl font-extrabold">1906</h6>
                </NavLink>
                <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>  
            </div>  
        </div> 

    ) 

}