/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
const glossarImages = [
    {
      title: 'Acrylnitril-Butadien-Styrol',
      size: 'Elastomer',
      source:
        'https://images.unsplash.com/photo-1493217465235-252dd9c0d632?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
    },
    {
      title: 'Butadien-KautschukButadien-Kautschuk',
      size: 'Elastomer',
      source:
        'https://images.unsplash.com/photo-1585997769088-95219fac87f4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
    },
    {
      title: 'Epoxidharz',
      size: 'Duroplast',
      source:
        'https://images.unsplash.com/photo-1609850808399-82fb0bb6fb32?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
    },
    {
      title: 'Ethylen-Chlortrifluorethylen',
      size: 'Thermoplast',
      source:
        'https://images.unsplash.com/photo-1536939459926-301728717817?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
    },
    {
      title: 'Melamin-Formaldehydharz',
      size: 'Duroplast',
      source:
        'https://images.unsplash.com/photo-1586013286823-5ba3576ea8d2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
    },
    {
      title: 'Polyacrylnitril',
      size: 'Teilkristalline Faser',
      source:
        'https://images.unsplash.com/photo-1515100122522-6efd24d7d218?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
    },
    {
      title: 'Polyether-Block-Amid',
      size: '	Thermoplastisches Elastomer',
      source:
        'https://images.unsplash.com/photo-1580053852056-f3992ab1e5e5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
    },
    // More files...
  ]
  
  export default function Glossar() {
    return (
      <div className="relative mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
        <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {glossarImages.map((glossarImage) => (
            <li key={glossarImage.source} className="relative">
              <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <img src={glossarImage.source} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
                <button type="button" className="absolute inset-0 focus:outline-none">
                  <span className="sr-only">View details for {glossarImage.title}</span>
                </button>
              </div>
              <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{glossarImage.title}</p>
              <p className="pointer-events-none block text-sm font-medium text-gray-500">{glossarImage.size}</p>
            </li>
          ))}
        </ul>
      </div>
    )
  }