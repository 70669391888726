import { NavLink } from "react-router-dom";
import { HomeIcon as HomeIcon } from '@heroicons/react/24/solid'

export default function HomeButton() {
    return(

        <NavLink type="button"
            className="z-40 absolute bottom-[40%] left-[-7.5rem] inline-flex items-center rounded-full border border-transparent bg-cyan-600 p-3 text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            to="/Navigation">

        <HomeIcon className="h-48 w-48 pl-28" aria-hidden="true" />
        </NavLink>


    )



}