import { NavLink } from "react-router-dom"
import { motion } from "framer-motion"

const collections = [
    {
      name: "Restaurierung",
      href: '/Restaurierung',
      imageSrc: '../Images/Restaurierung-tn.jpg',
      imageAlt: 'Woman wearing an off-white cotton t-shirt.',
    },
    {
      name: "Technik",
      href: '/Technik',
      imageSrc: '../Images/Techniker-tn.jpg',
      imageAlt: 'Man wearing a charcoal gray cotton t-shirt.',
    },
    {
      name: 'Produktion',
      href: '/Produktionsverfahren',
      imageSrc: '../Images/3D_Druck.PNG',
      imageAlt: 'Person sitting at a wooden desk with paper note organizer, pencil and tablet.',
    },
  ]
  
  export default function Navigation() {
    return (
      <motion.div className="h-screen" initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}}>
      <div className="relative z-50 bg-white">
        {/* Background image and overlap */}
        <div aria-hidden="true" className="absolute inset-0 hidden sm:flex sm:flex-col">
          <div className="relative w-full flex-1 bg-gray-800">
            <div className="absolute inset-0 overflow-hidden">
              <img
                src="../Images/Start/Start.jpeg"
                alt=""
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div className="absolute inset-0 bg-gray-900 opacity-50" />
          </div>
          <div className="h-32 w-full bg-white md:h-40 lg:h-48" />
        </div>
  
        <div className="relative mx-auto max-w-5xl px-4 pb-96 text-center sm:px-6 sm:pb-0 lg:px-8">
          {/* Background image and overlap */}
          <div aria-hidden="true" className="absolute inset-0 flex flex-col sm:hidden">
            <div className="relative w-full flex-1 bg-gray-800">
              <div className="absolute inset-0 overflow-hidden">
                <img
                  src="../Images/Start/Start.jpeg"
                  alt=""
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <div className="absolute inset-0 bg-gray-900 opacity-50" />
            </div>
            <div className="h-48 w-full bg-white" />
          </div>
          <div className="relative py-32">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl md:text-8xl">Kunststoffe erklärt...</h1>
          </div>
        </div>
  
        <section aria-labelledby="collection-heading" className="relative -mt-96 sm:mt-0">
          <h2 id="collection-heading" className="sr-only">
            Collections
          </h2>
          <div className="mx-auto grid max-w-md grid-cols-1 gap-y-6 px-4 sm:max-w-7xl sm:grid-cols-3 sm:gap-y-0 sm:gap-x-6 sm:px-6 lg:gap-x-8 lg:px-8">
            {collections.map((collection) => (
              <div
                key={collection.name}
                className="group relative h-96 rounded-lg bg-white shadow-xl sm:aspect-w-4 sm:aspect-h-5 sm:h-auto"
              >
                <div>
                  <div aria-hidden="true" className="absolute inset-0 overflow-hidden rounded-lg">
                    <div className="absolute inset-0 overflow-hidden group-hover:opacity-75">
                      <img
                        src={collection.imageSrc}
                        alt={collection.imageAlt}
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                    <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50" />
                  </div>
                  <div className="absolute inset-0 flex items-end rounded-lg p-6">
                    <div>
                      <p aria-hidden="true" className="text-sm text-white">
                        Berühren um fortzufahren
                      </p>
                      <h3 className="mt-1 text-5xl font-semibold text-white">
                        <NavLink to={collection.href}>
                          <span className="absolute inset-0" />
                          {collection.name}
                        </NavLink>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
      </motion.div>
    )
  }