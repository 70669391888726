import Zeitstrahl from "./Zeitstrahl";
const features = [
    {
        name: 'Plastik Bums',
        description:
            'The machined kettle has a smooth black finish and contemporary shape that stands apart from most plastic appliances.',
    },
    { name: 'Comfort handle', description: 'Shaped for steady pours and insulated to prevent burns.' },
    {
        name: 'One-button control',
        description:
            'The one button control has a digital readout for setting temperature and turning the kettle on and off.',
    },
    { name: 'Long spout', description: "Designed specifically for controlled pour-overs that don't slash or sputter." },
]
const timelineelements = [
    {
        year: '1901',
        description:
            'The machined kettle has a smooth black finish and contemporary shape that stands apart from most plastic appliances.',
        link:
            '#',
        color:
            'bg-blue-500',
    },
    {
        year: '1902',
        description:
            'The machined kettle has a smooth black finish and contemporary shape that stands apart from most plastic appliances.',
        link:
            '#',
    },
    {
        year: '1903',
        description:
            'The machined kettle has a smooth black finish and contemporary shape that stands apart from most plastic appliances.',
        link:
            '#',
    },
    {
        year: '1904',
        description:
            'The machined kettle has a smooth black finish and contemporary shape that stands apart from most plastic appliances.',
        link:
            '#',
    },
    
]
const color1 = 'yellow-500';
const color2 = 'amber-600';
export default function Bunt() {
return(
<div className="overflow-hidden bg-white">
                    <div className={"absolute top-0 bottom-0 left-3/4 overflow-hidden hidden w-1/4 lg:block bg-"+color1} />

                <div className="relative mx-auto max-w-7xl pt-16 px-4 sm:px-6 lg:px-8">
                    <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
                        <div className="mt-8 lg:mt-0">
                            <div>
                                <div className="border-b border-gray-200 pb-10">
                                    <h2 className="font-medium text-gray-500">Machined Kettle</h2>
                                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-7xl">Elegant simplicity</p>
                                </div>

                                <dl className="mt-10 space-y-10">
                                    {features.map((feature) => (
                                        <div key={feature.name}>
                                            <dt className="text-sm font-medium text-gray-900">{feature.name}</dt>
                                            <dd className="mt-3 text-sm text-gray-500">{feature.description}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>
                        </div>
                        <div className="relative lg:col-start-2 lg:row-start-1">
                            <svg
                                className="absolute top-0 right-0 -mt-20 -mr-20 hidden lg:block"
                                width={404}
                                height={384}
                                fill="none"
                                viewBox="0 0 404 384"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect x={0} y={0} width={4} height={4} className={"text-"+color2} fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width={404} height={384} fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
                            </svg>
                            <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
                                <figure>
                                    <div>
                                        <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-gray-100">
                                            <img
                                                src="https://tailwindui.com/img/ecommerce-images/product-feature-09-main-detail.jpg"
                                                alt="Black kettle with long pour spot and angled body on marble counter next to coffee mug and pour-over system."
                                                className="h-full w-full object-cover object-center"
                                            />
                                        </div>
                                        <div className="mt-4 grid grid-cols-2 gap-4 sm:mt-6 sm:gap-6 lg:mt-8 lg:gap-8">
                                            <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-gray-100">
                                                <img
                                                    src="https://tailwindui.com/img/ecommerce-images/product-feature-09-detail-01.jpg"
                                                    alt="Detail of temperature setting button on kettle bass with digital degree readout."
                                                    className="h-full w-full object-cover object-center"
                                                />
                                            </div>
                                            <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-gray-100">
                                                <img
                                                    src="https://tailwindui.com/img/ecommerce-images/product-feature-09-detail-02.jpg"
                                                    alt="Kettle spout pouring boiling water into coffee grounds in pour-over mug."
                                                    className="h-full w-full object-cover object-center"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </figure>
                            </div>
                        </div>
                    </div>

    <Zeitstrahl/>
     </div>
    </div>
      
        ) 

}

function Right() {
    return (
        <div className="overflow-hidden bg-white">
                    <div className={"absolute top-[108px] bottom-0 right-3/4 hidden w-screen lg:block bg-"+color1} />

                <div className="relative mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
                    <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
                        <div className="mt-8 lg:mt-0 lg:col-start-2">
                            <div>
                                <div className="border-b border-gray-200 pb-10">
                                    <h2 className="font-medium text-gray-500">Machined Kettle</h2>
                                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-9xl">Elegant simplicity</p>
                                </div>

                                <dl className="mt-10 space-y-10">
                                    {features.map((feature) => (
                                        <div key={feature.name}>
                                            <dt className="text-sm font-medium text-gray-900">{feature.name}</dt>
                                            <dd className="mt-3 text-sm text-gray-500">{feature.description}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>
                        </div>
                        <div className="relative  lg:row-start-1">
                            <svg
                                className="absolute top-0 left-0 -mt-20 -ml-20 hidden lg:block"
                                width={404}
                                height={384}
                                fill="none"
                                viewBox="0 0 404 384"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect x={0} y={0} width={4} height={4} className={"text-"+color2} fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width={404} height={384} fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
                            </svg>
                            <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
                                <figure>
                                    <div>
                                        <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-gray-100">
                                            <img
                                                src="https://tailwindui.com/img/ecommerce-images/product-feature-09-main-detail.jpg"
                                                alt="Black kettle with long pour spot and angled body on marble counter next to coffee mug and pour-over system."
                                                className="h-full w-full object-cover object-center"
                                            />
                                        </div>
                                        <div className="mt-4 grid grid-cols-2 gap-4 sm:mt-6 sm:gap-6 lg:mt-8 lg:gap-8">
                                            <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-gray-100">
                                                <img
                                                    src="https://tailwindui.com/img/ecommerce-images/product-feature-09-detail-01.jpg"
                                                    alt="Detail of temperature setting button on kettle bass with digital degree readout."
                                                    className="h-full w-full object-cover object-center"
                                                />
                                            </div>
                                            <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-gray-100">
                                                <img
                                                    src="https://tailwindui.com/img/ecommerce-images/product-feature-09-detail-02.jpg"
                                                    alt="Kettle spout pouring boiling water into coffee grounds in pour-over mug."
                                                    className="h-full w-full object-cover object-center"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

function Left() {
    return (
        <div className="overflow-hidden bg-white">
                    <div className={"absolute top-[108px] bottom-0 left-3/4 hidden w-screen lg:block bg-"+color1} />

                <div className="relative mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
                    <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
                        <div className="mt-8 lg:mt-0">
                            <div>
                                <div className="border-b border-gray-200 pb-10">
                                    <h2 className="font-medium text-gray-500">Machined Kettle</h2>
                                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-9xl">Elegant simplicity</p>
                                </div>

                                <dl className="mt-10 space-y-10">
                                    {features.map((feature) => (
                                        <div key={feature.name}>
                                            <dt className="text-sm font-medium text-gray-900">{feature.name}</dt>
                                            <dd className="mt-3 text-sm text-gray-500">{feature.description}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>
                        </div>
                        <div className="relative lg:col-start-2 lg:row-start-1">
                            <svg
                                className="absolute top-0 right-0 -mt-20 -mr-20 hidden lg:block"
                                width={404}
                                height={384}
                                fill="none"
                                viewBox="0 0 404 384"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect x={0} y={0} width={4} height={4} className={"text-"+color2} fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width={404} height={384} fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
                            </svg>
                            <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
                                <figure>
                                    <div>
                                        <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-gray-100">
                                            <img
                                                src="https://tailwindui.com/img/ecommerce-images/product-feature-09-main-detail.jpg"
                                                alt="Black kettle with long pour spot and angled body on marble counter next to coffee mug and pour-over system."
                                                className="h-full w-full object-cover object-center"
                                            />
                                        </div>
                                        <div className="mt-4 grid grid-cols-2 gap-4 sm:mt-6 sm:gap-6 lg:mt-8 lg:gap-8">
                                            <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-gray-100">
                                                <img
                                                    src="https://tailwindui.com/img/ecommerce-images/product-feature-09-detail-01.jpg"
                                                    alt="Detail of temperature setting button on kettle bass with digital degree readout."
                                                    className="h-full w-full object-cover object-center"
                                                />
                                            </div>
                                            <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-gray-100">
                                                <img
                                                    src="https://tailwindui.com/img/ecommerce-images/product-feature-09-detail-02.jpg"
                                                    alt="Kettle spout pouring boiling water into coffee grounds in pour-over mug."
                                                    className="h-full w-full object-cover object-center"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}
