import { NavLink, useParams } from 'react-router-dom';
  const restaurierungVideos = [
    {
      title: 'Was macht Kunststoffe interessant?',
      size: '3',
      source_tn: '../Images/tn/Restaurierung/101.jpg',
      source_vid: '../Videos/Restaurierung/101.mp4',
      interviewer: 'Prof. Dr. Friederike Waentig',
      institute: 'Institut für Restaurierungs- und Konservierungswissenschaften Technische Hochschule Köln (CICS)',
      duration: '3',
      recommendations: [
        {
          id: 4,
        },
        {
          id: 8,
        },
        {
          id: 6,
        }
      ]
    },
    {
      title: 'Wie wird ein Objekt restauriert?',
      size: '3',
      source_tn: '../Images/tn/Restaurierung/102.jpg',
      source_vid: '../Videos/Restaurierung/102.mp4',
      interviewer: 'Prof. Dr. Friederike Waentig',
      institute: 'Institut für Restaurierungs- und Konservierungswissenschaften Technische Hochschule Köln (CICS)',
      duration: '3',
      recommendations: [
        {
          id: 0,
        },
        {
          id: 5,
        },
        {
          id: 10,
        }
      ]
    },
    {
      title: 'Wie gehen Sie vor, wenn Sie ein Objekt erhalten?',
      size: '3',
      source_tn: '../Images/tn/Restaurierung/103.jpg',
      source_vid: '../Videos/Restaurierung/103.mp4',
      source_sub: '../Videos/Restaurierung/sub/103.vtt',
      interviewer: 'Prof. Dr. Friederike Waentig',
      institute: 'Institut für Restaurierungs- und Konservierungswissenschaften Technische Hochschule Köln (CICS)',
      duration: '3',
      recommendations: [
        {
          id: 9,
        },
        {
          id: 7,
        },
        {
          id: 2,
        }
      ]
    },
    {
      title: 'Wie identifizieren Sie einen Kunststoff?',
      size: '3',
      source_tn: '../Images/tn/Restaurierung/104.jpg',
      source_vid: '../Videos/Restaurierung/104.mp4',
      interviewer: 'Prof. Dr. Friederike Waentig',
      institute: 'Institut für Restaurierungs- und Konservierungswissenschaften Technische Hochschule Köln (CICS)',
      text: 'clloer text',
      duration: '3',
      recommendations: [
        {
          id: 3,
        },
        {
          id: 0,
        },
        {
          id: 5,
        }
      ]
    },
    {
      title: 'Was macht das Restaurieren von Kunststoffen anspruchsvoll?',
      size: '3',
      source_tn: '../Images/tn/Restaurierung/105.jpg',
      source_vid: '../Videos/Restaurierung/105.mp4',
      interviewer: 'Prof. Dr. Friederike Waentig',
      institute: 'Institut für Restaurierungs- und Konservierungswissenschaften Technische Hochschule Köln (CICS)',
      duration: '3',
      recommendations: [
        {
          id: 4,
        },
        {
          id: 8,
        },
        {
          id: 6,
        }
      ]
    },
    {
      title: 'Was sind die besten und schlechtesten Eigenschaften von Kunststoffen?',
      size: '3',
      source_tn: '../Images/tn/Restaurierung/106.jpg',
      source_vid: '../Videos/Restaurierung/106.mp4',
      interviewer: 'Prof. Dr. Friederike Waentig',
      institute: 'Institut für Restaurierungs- und Konservierungswissenschaften Technische Hochschule Köln (CICS)',
      duration: '3',
      recommendations: [
        {
          id: 0,
        },
        {
          id: 5,
        },
        {
          id: 10,
        }
      ]
    },
    {
      title: 'Wie haben Kunststoffe die moderne Welt geprägt?',
      size: '3',
      source_tn: '../Images/tn/Restaurierung/107.jpg',
      source_vid: '../Videos/Restaurierung/107.mp4',
      source_sub: '../Videos/Restaurierung/sub/107.vtt',
      interviewer: 'Prof. Dr. Friederike Waentig',
      institute: 'Institut für Restaurierungs- und Konservierungswissenschaften Technische Hochschule Köln (CICS)',
      duration: '3',
      recommendations: [
        {
          id: 9,
        },
        {
          id: 7,
        },
        {
          id: 2,
        }
      ]
    },
    {
      title: 'Was sind Schwerpunkte in Ihrer Arbeit?',
      size: '3',
      source_tn: '../Images/tn/Restaurierung/108.jpg',
      source_vid: '../Videos/Restaurierung/108.mp4',
      interviewer: 'Prof. Dr. Friederike Waentig',
      institute: 'Institut für Restaurierungs- und Konservierungswissenschaften Technische Hochschule Köln (CICS)',
      text: 'clloer text',
      duration: '3',
      recommendations: [
        {
          id: 3,
        },
        {
          id: 0,
        },
        {
          id: 5,
        }
      ]
    },
    {
      title: 'Werden wir in 100 Jahren noch Kunststoffe verwenden?',
      size: '3',
      source_tn: '../Images/tn/Restaurierung/109.jpg',
      source_vid: '../Videos/Restaurierung/109.mp4',
      interviewer: 'Prof. Dr. Friederike Waentig',
      institute: 'Institut für Restaurierungs- und Konservierungswissenschaften Technische Hochschule Köln (CICS)',
      duration: '3',
      recommendations: [
        {
          id: 0,
        },
        {
          id: 5,
        },
        {
          id: 10,
        }
      ]
    },
    {
      title: 'Wie sollten wir mit Kunststoffen umgehen?',
      size: '3',
      source_tn: '../Images/tn/Restaurierung/110.jpg',
      source_vid: '../Videos/Restaurierung/110.mp4',
      source_sub: '../Videos/Restaurierung/sub/107.vtt',
      interviewer: 'Prof. Dr. Friederike Waentig',
      institute: 'Institut für Restaurierungs- und Konservierungswissenschaften Technische Hochschule Köln (CICS)',
      duration: '3',
      recommendations: [
        {
          id: 9,
        },
        {
          id: 7,
        },
        {
          id: 2,
        }
      ]
    },
    {
      title: 'Was ist Ihre beste Geschichte über Kunststoffe?',
      size: '3',
      source_tn: '../Images/tn/Restaurierung/111.jpg',
      source_vid: '../Videos/Restaurierung/111.mp4',
      interviewer: 'Prof. Dr. Friederike Waentig',
      institute: 'Institut für Restaurierungs- und Konservierungswissenschaften Technische Hochschule Köln (CICS)',
      text: 'clloer text',
      duration: '3',
      recommendations: [
        {
          id: 3,
        },
        {
          id: 0,
        },
        {
          id: 5,
        }
      ]
    },
    // More Videos
  ]





export default function RestaurierungVideo() {
  var { id } = useParams();
    return (
      <div className="bg-white">
        <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
          <div className="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
            <svg
              className="absolute top-12 left-full translate-x-32 transform"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
          </div>
        </div>


      
      
        <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="mb-8">
            <h2 className="text-lg font-semibold text-amber-500">Interview</h2>
            <h1 className="mt-1 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
              Restaurierung
            </h1>
          </div>

        <div className="grid grid-cols-4 gap-12">
            <div className="col-span-3 ">
                <video className="rounded-lg object-cover shadow-lg" width="100%" height="auto" controls src={restaurierungVideos[id].source_vid} type="video/mp4">
                    Your browser does not support the video tag.  
                </video>
                <h3 className="mt-1 text-4xl font-bold tracking-tight text-gray-900">
                  {restaurierungVideos[id].title}
                </h3>
                <h5 className="pt-2 text-lg font-semibold text-amber-500">
                {restaurierungVideos[id].interviewer}
                </h5>
                <h6 className="font-semibold text-gray-500">
                {restaurierungVideos[id].institute}
                </h6>
            </div>

            <div className=''>
            <ul className="grid grid-cols-1">
              {restaurierungVideos[id].recommendations.map((reco) => (
                <li key={restaurierungVideos[reco.id].source_tn} className="relative pb-8">
                  <div className="group aspect-video block w-full place-content-center overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-amber-400 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                    <img src={restaurierungVideos[reco.id].source_tn} alt="" className="block pointer-events-none object-cover group-hover:opacity-75" />
                    <NavLink to={"/RestaurierungVideo/" + reco.id} type="button" className="absolute inset-0 focus:outline-none">
                      <span className="sr-only">View details for {restaurierungVideos[reco.id].title}</span>
                    </NavLink>
                  </div>
                  <p className="pointer-events-none mt-2 block text-lg font-bold text-gray-900">{restaurierungVideos[reco.id].title}</p>
                  <p className="pointer-events-none block text-sm font-medium text-gray-500">Dauer ca. {restaurierungVideos[reco.id].duration} Minuten</p>
                </li>
              ))}
            </ul>

            </div>

        </div>

       

        </div>
      </div>
    )
  }